import {
    SET_HOTDESK,
    SET_RECENT_SIP_ACCOUNTS,
    SET_HOTDESK_LOADING,
    SET_HOTDESK_NUMBER,
    SET_HOTDESK_QUEUES,
    SET_HOTDESKS,
    SET_INCOMING_CALLS,
    SET_MISSED_CALLS,
    SET_OUTGOING_CALLS,
    SET_QUEUE_STATUS,
    SET_QUEUE_TYPE,
    SET_QUEUES,
    SET_PHONEBOOK_ENTRIES,
} from "./mutation-types";
import axios from "axios";

const actions = {
    loadHotdeskQueues({ commit }) {
        axios
            .get("/api/v1/queue/my/")
            .then((response) => {
                commit(SET_HOTDESK_QUEUES, {
                    queues: response.data.results,
                });
            })
            .catch((e) => {
                // TODO add error handling
                console.error(e);
            });
    },

    setQueueStatus({ commit }, { queue, status }) {
        commit(SET_QUEUE_STATUS, {
            queue: queue,
            status: status,
        });
        let data = new FormData();
        data.set("status", status);
        axios
            // FIXME use API endpoint
            .post("/asterisk/queue/" + queue + "/set-status/", data)
            .then((response) => {
                // the status will be changed by a message via WebSockets
            })
            .catch((e) => {
                // TODO add error handling
                console.error(e);
            });
    },

    setQueueType({ commit }, { queue, type }) {
        commit(SET_QUEUE_TYPE, {
            queue: queue,
            type: type,
        });
        let data = new FormData();
        data.set("type", type);
        axios
            // FIXME use API endpoint
            .post("/asterisk/queue/" + queue + "/set-type/", data)
            .then((response) => {
                // the type will be changed by a message via WebSockets
            })
            .catch((e) => {
                // TODO add error handling
                console.error(e);
            });
    },

    makeCall({}, { phoneNumber }) {
        let data = new FormData();
        data.set("phone_number", phoneNumber);
        axios
            // FIXME use API endpoint
            .post("/asterisk/call/make/", data)
            .then((response) => {
                // TODO show message
                console.debug("started call to: " + phoneNumber);
            })
            .catch((e) => {
                // TODO add error handling
                console.error(e);
            });
    },

    loadMissedCalls({ commit }) {
        axios
            .get("/asterisk/call/missed/")
            .then((response) => {
                commit(SET_MISSED_CALLS, {
                    calls: response.data.results,
                });
            })
            .catch((e) => {
                // TODO add error handling
                console.error(e);
            });
    },

    loadIncomingCalls({ commit }) {
        axios
            .get("/asterisk/call/incoming/")
            .then((response) => {
                commit(SET_INCOMING_CALLS, {
                    calls: response.data.results,
                });
            })
            .catch((e) => {
                // TODO add error handling
                console.error(e);
            });
    },

    loadOutgoingCalls({ commit }) {
        axios
            .get("/asterisk/call/outgoing/")
            .then((response) => {
                commit(SET_OUTGOING_CALLS, {
                    calls: response.data.results,
                });
            })
            .catch((e) => {
                // TODO add error handling
                console.error(e);
            });
    },

    loadHotdesks({ commit }) {
        axios
            // FIXME use API endpoint
            .get("/api/v1/hotdesk/")
            .then((response) => {
                commit(SET_HOTDESKS, {
                    hotdesks: response.data.results,
                });
            })
            .catch((e) => {
                // TODO add error handling
                console.error(e);
            });
    },

    loadQueues({ commit }) {
        axios
            .get("/api/v1/queue/")
            .then((response) => {
                commit(SET_QUEUES, {
                    queues: response.data.results,
                });
            })
            .catch((e) => {
                // TODO add error handling
                console.error(e);
            });
    },

    loadUserHotdesk({ commit }) {
        axios
            .get("/api/v1/hotdesk/me/")
            .then((response) => {
                commit(SET_HOTDESK, {
                    hotdesk: response.data,
                });
                commit(SET_HOTDESK_NUMBER, {
                    hotdeskNumber: response.data.number,
                });
                commit(SET_RECENT_SIP_ACCOUNTS, {
                    recentSipAccounts:
                        response.data.last_recent_sip_account_names,
                });
            })
            .catch((e) => {
                // TODO add error handling
                console.error(e);
            });
    },

    loadPhonebookEntry({ commit }) {
        axios
            .get("/api/v1/local/phonebookentry/")
            .then((response) => {
                commit(SET_PHONEBOOK_ENTRIES, {
                    phonebookentries: response.data.results,
                });
            })
            .catch((e) => {
                // TODO add error handling
                console.error(e);
            });
    },

    setHotdesk({ commit }, { hotdesk }) {
        commit(SET_HOTDESK, {
            hotdesk,
        });
    },

    setHotdeskNumber({ commit }, { hotdeskNumber }) {
        commit(SET_HOTDESK_NUMBER, {
            hotdeskNumber,
        });
    },

    setHotdeskLoading({ commit }, { isLoading }) {
        commit(SET_HOTDESK_LOADING, {
            isLoading,
        });
    },

    attachHotdesk({ commit }, { hotdeskNumber, hotdeskPin, sipAccount }) {
        commit(SET_HOTDESK_LOADING, {
            isLoading: true,
        });
        let data = new FormData();
        data.set("number", hotdeskNumber);
        data.set("pin", hotdeskPin);
        data.set("sip_account_name", sipAccount);
        axios
            .post("/asterisk/hotdesk/remote-login/", data)
            .then((response) => {
                // TODO show message
                if (response.data === "OK") {
                    console.debug("attached hotdesk");
                } else {
                    console.debug("failed to attach hotdesk");
                }
            })
            .catch((e) => {
                // TODO add error handling
                console.error(e);
            });
    },

    detachHotdesk({ commit, state }, { sipAccount }) {
        commit(SET_HOTDESK_LOADING, {
            isLoading: true,
        });
        // set queue status instant on state to let the user feel like he is paused on all queues
        for (let queue of Object.entries(state.hotdeskQueues)) {
            commit(SET_QUEUE_STATUS, {
                queue: queue.name,
                status: 1,
            });
        }
        let data = new FormData();
        data.set("sip_account_name", sipAccount);
        axios
            .post("/asterisk/hotdesk/remote-logout/", data)
            .then((response) => {
                // TODO show message
                if (response.data === "OK") {
                    console.debug("detached hotdesk");
                } else {
                    console.debug("failed to detach hotdesk");
                }
            })
            .catch((e) => {
                // TODO add error handling
                console.error(e);
            });
    },
};

export default actions;
